import React, {useEffect}  from 'react';

import IndexContent from "../content/IndexContent";

import Navbar from "../inc/Navbar";
import Banner from "../inc/Banner";
import Testimonials from "../inc/Testimonials";
import Footer from "../inc/Footer";
import Team from "../inc/Team";
import {Helmet} from 'react-helmet';
import Blog from '../inc/Blog';

function Home() {

    const canonicalUrl = window.location.href; // Get current URL

    useEffect(()=>{
        window.scroll({top: 0, behavior: 'smooth'});
    },[]);

    return(
        <>
            <Helmet>
                <title>Cheap Web Guru Solutions: Web &amp; Digital Marketing Agency</title>
            </Helmet>
            <Helmet>  
                <meta name="keywords" content="web development leading company in india, web design, digital marketing"/>
            </Helmet>
            <Helmet>    
                <meta name="description" content="Cheap Web Guru Solutions is a 10-year-old digital services company specializing in web development, app development, web design, digital marketing." />
            </Helmet>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <Navbar />
            <Banner />
            <IndexContent/>
            {/* <Team /> */}
            <Blog />
            <Testimonials />
            <Footer />        
        </>
    );
}

export default Home;