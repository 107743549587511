import { useEffect } from 'react';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

function WpComponent() {
//   const [ref, setRef] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const refValue = queryParams.get("ref");
    // setRef(refValue);
    if (refValue!=null && refValue!='') {
        sessionStorage.setItem("myRefValue", refValue);
    }
    
    // alert(sessionStorage.getItem("myRefValue"));

  }, []);

  return (
    <>

        {sessionStorage.getItem("myRefValue")!=null && sessionStorage.getItem("myRefValue")=="google"?
        <CardMedia
            component="img"  // Specifies the component used for the root node (img in this case)
            height="80"      // The height of the media
            image="https://cheapwebguru.com/images/wp_icon.png"  // The URL or path to the image
            alt="Alt text"    // Alternative text for the image
            onClick={()=>{
                window.open("https://wa.me/918910834910?text=I%20am%20interested%20in%20my%20project%20development.")
            }}
        />
        :
        <CardMedia
            component="img"  // Specifies the component used for the root node (img in this case)
            height="80"      // The height of the media
            image="https://cheapwebguru.com/images/wp_icon.png"  // The URL or path to the image
            alt="Alt text"    // Alternative text for the image
            onClick={()=>{
                window.open("https://wa.me/918013561965?text=I%20am%20interested%20in%20my%20project%20development.")
            }}
        />
        }

    </>
    );
};

export default WpComponent;