import React, {useEffect}  from 'react';

import WebhostingContent from "../content/WebhostingContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";
import {Helmet} from 'react-helmet';

import IndexContent from "../content/IndexContent";

function Webhosting() {

    const canonicalUrl = window.location.href; // Get current URL

    useEffect(()=>{
        window.scroll({top: 0, behavior: 'smooth'});
    },[]);

    return(
        <>
        <Helmet>
            <title>Cheap Web Guru Solutions: Web Hosting</title>
        </Helmet>
        <Helmet>
            <meta name="keywords" content="web hosting, web development, web design, hosting"/>
        </Helmet>
        <Helmet>
            <meta name="description" content="Cheap Web Guru Solutions is a 10-year-old digital services company specializing in web development, app development, web design, digital marketing. Founded in 2015, Cheap Web Guru Solutions has grown to become a leader in the industry, providing innovative solutions to businesses and organizations of all sizes." />
        </Helmet>
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <Navbar />
        {/* <IndexContent /> */}
        <WebhostingContent />
        <Footer />        
        </>
    );
}

export default Webhosting;